import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/articles-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Páginas como esta que están escritas con Gatsby y utilizar archivos `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Markdown`}</code>{` para generar nuevos artículos. Existe por defecto soporte para bloques de código, como el utilizado en la oración anterior.`}</p>
    <p>{`El problema es que no existe un resaltado de sintaxis o `}<em parentName="p">{`syntax highlighting`}</em>{`. La forma más sencilla de resolver este problema en Gatsby es utilizando `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://prismjs.com/"
        }}>{`PrismJs`}</a></strong>{`.`}</p>
    <p>{`Existen 2 tipos de código de bloque: `}<em parentName="p">{`Inline code`}</em>{` y `}<em parentName="p">{`Code Block`}</em></p>
    <h2>{`Inline Code`}</h2>
    <p>{`Inline Code es simplemente código que puede estar en la mima línea entre otro texto.`}</p>
    <p>{`Por ejemplo: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`console.log('hola')`}</code>{` es un inline Block. Este se escribe utilizando el simbolo de la tílde inversa (`}<strong parentName="p">{`\``}</strong>{` `}<strong parentName="p">{`\``}</strong>{`) y ubicando dentro de este el código que necesitamos:`}</p>
    <p>{`La línea anterior se la consigue de esta manera:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Por ejemplo: \`console.log('hola')\` es un inline Block.`}</code></pre></div>
    <h2>{`Code Block`}</h2>
    <p>{`Un Bloque de código no es más que código que puede ocupar varias líneas.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Este es un bloque de código`}</span>{`
console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Hola de nuevo'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Para definir un bloque de código se utiliza la misma tilde inversa, pero esta vez necesitamos utilizar `}<strong parentName="p">{`3`}</strong>{` tildes para abrir y otras 3 para cerrar el bloque.`}</p>
    <h2>{`Instalación de Prismjs`}</h2>
    <p>{`Vamos a utilizar el plugin `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.gatsbyjs.org/packages/gatsby-remark-prismjs/"
        }}>{`gatsby-remark-prismjs`}</a></strong></p>
    <p>{`Lo instalamos:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`npm install --save gatsby-transformer-remark gatsby-remark-prismjs prismjs
`}</code></pre></div>
    <h2>{`Uso`}</h2>
    <p>{`Necesitamos agregarlo a la configuración en el archivo `}<strong parentName="p">{`gatsby-config.js`}</strong>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`plugins`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    resolve`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`gatsby-transformer-remark`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      plugins`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          resolve`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`gatsby-remark-prismjs`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`El ultimo paso es `}<strong parentName="p">{`incluirlo la hoja de estilo`}</strong>{` en nuestro proyecto. En el archivo `}<strong parentName="p">{`gatsby-browser.js`}</strong>{` agregamos el tema que queremos utilizar:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'prismjs/themes/prism-okaidia.css'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`El listado de temas se los puede encontrar en `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https:/prismjs.com/"
        }}>{`https://prismjs.com/`}</a></strong>{`. Y podemos cambiar al que más nos guste.`}</p>
    <p>{`Esta pagina utiliza el tema `}<em parentName="p">{`okaidia`}</em>{` con algunos ajustes personales.`}</p>
    <h2>{`Personalización`}</h2>
    <p>{`Si al igual que yo no quieres que tu diseño sea exactamente igual al que se podría encontrar en otros sitios es muy sencillo realizar modificaciones y sobrescribir los estilos del tema. Simplemente agregamos los estilos en una hoja de estilos que estemos utilizando, en mi caso `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/css/style.css`}</code>{`.`}</p>
    <p>{`Este sitio (al momento de escribir este tutorial utiliza los siguientes estilos).`}</p>
    <h3>{`Modificando la apariencia de inline Block`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`:not(pre) > code[class*='language-']`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #35bfee`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Modificando la apariencia de block code`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.gatsby-highlight`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #1f1f1f`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.3em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5em 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` auto`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.gatsby-highlight pre[class*='language-']`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` transparent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` initial`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`float`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` left`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`min-width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Modificando la línea resaltada`}</h3>
    <p>{`PrismJs permite resaltar líneas en el código de bloque. Esto se puede hacer agregando el comentario: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`// highlight-next-line`}</code>{` antes de la línea que deseamos resaltar:`}</p>
    <p>{`Su estilo se modifica con:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.gatsby-highlight-code-line`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background-color`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` #2a4365`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-right`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding-right`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.75em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.25em solid #39DBF9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      